import React, { useEffect, useState } from 'react'
import '../header/header.css'
import logoEvent from '../../assets/img/level1-2024/assets-03.png'

const Navbar = () => {
  const [clicked, setClicked] = useState(false)
  const [sticky, setStickyClass] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('active') : setStickyClass('');
    }
  }

  const handleClick = () => {
    setClicked(!clicked)
  }

  return (
    <div className="header" id='header'>
      <div className="banner">
        <div className={`navbar ${sticky ? `active` : ''}`}>
          <div className='nav-menu'>
            <ul className={`nav-list ${clicked ? `active` : ''}`}>
              <li onClick={handleClick}><a href="#header">Home</a></li>
              <li onClick={handleClick}><a href="#about">Apa itu WEF</a></li>
              <li onClick={handleClick}><a href="#lokasi">Lokasi & Tanggal</a></li>
              <li onClick={handleClick}><a href="#workshop">Workshop</a></li>
            </ul>
          </div>
          <div className="bar-menu" onClick={handleClick}>
            <i className="fa-solid fa-bars-staggered"></i>
          </div>
        </div>
        <img className='banner-pirate' src={logoEvent} alt="banner" />
        {/* <img className='banner-festival' src={BannerFestival} /> */}
      </div>
    </div>
  )
}

export default Navbar