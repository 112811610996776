import React, { Component } from 'react';
import API_URL from '../../API';
import { Container, Row, Col, Toast } from "reactstrap";
import '../../assets/css/style.css';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const withRouterParams = (Component) => {
    return(props) => {
        const params = useParams()
        return <Component {...params} params={params} />;
    }
}

class Attandance extends Component {

    constructor() {
        super();
        this.state = {
            id_event: API_URL.id_event,
            code_register: '',
            height:window.innerHeight,
            register_city_all: []
        }
        this.setFullScreen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.setFullScreen);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.setFullScreen);
    }

    setFullScreen = () => {
        var topSectionEl = document.getElementById('home-fullscreen');
        if(topSectionEl.clientHeight>0) {
            var windowHeight = window.innerHeight;
            this.setState({ 
                height: windowHeight,
                width:window.innerWidth
            });
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(event) {
        let formData = new FormData()

        formData.append('id_event', this.state.id_event)
        formData.append('code_register', this.state.code_register) 
        formData.append('status', 1)

        API_URL.eventUrl.post('world-education-festival/attandance', formData)
        .then(response => {
            response.data.message = 'Attandance Member' ?
            Swal.fire({
                title: response.data.message,
                icon: 'info',
                type: 'success',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
            :
            Swal.fire({
                title: response.data.status,
                icon: 'success',
                type: 'success',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        })
        .catch(error => {
            Swal.fire({  
                title: error.response.data.status,
                icon: 'error',
                text: error.response.data.message,  
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });;
        })

        event.preventDefault();
    }

    render() {
        return (
            <div className='bg-header-banner'>
                <section className="home">
                    <Container>
                        <div className="home-fullscreen" id="home-fullscreen" style={{height : this.state.height+"px"}}>
                            <div className="full-screen">
                                <div className="home-wrapper home-wrapper-alt">
                                    <form onSubmit={this.handleSubmit}>
                                        <Row className='justify-content-center'>
                                            <Col sm='6'>
                                                <h1 className='font-helloween'>Attendance</h1>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col sm='6'>
                                                <input className='form-control' type="text" name="code_register" autoFocus="autoFocus" onChange={(e) => this.handleChange(e)} />
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col sm='2' className='pr-5 mt-5'>
                                                <button className="btn btn-custom font-helloween-h2" type="submit">ENTER</button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        )
    }
}

export default withRouterParams(Attandance);